
import { Component, Vue, Ref } from 'vue-property-decorator';
import Icon from '@/components/ui/icon/Icon.vue';
import { removeUser } from '@/api/user/actions/deleteUser'
import UsersModule from '@/store/modules/users/UsersModule';
import { rmCookie } from '@/composables/cookie'

@Component({
	name: 'DeleteAccPopup',
	components: { Icon},
})

export default class DeleteAcc extends Vue{
  deleteAccount() {
    removeUser(UsersModule.currentUserId)
      .then(()=> {
        setTimeout(()=> {
          rmCookie('Authorization')
          location.reload()
        }, 300)
      })
  }
}
